import React from "react";
import Portfolio from "./Portfolio";
import './App.css';

export default function App() {
    return (
      <div className="App">
        <Portfolio />
      </div>
    );
}